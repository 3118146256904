.App {
  text-align: center;
}

.App-wrapper {
  background-image: url('./assets/images/backgrounds/XLback.jpg');
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgba(223, 233, 248, 0.5);
  backdrop-filter: blur(10px);

  .header-actions {
    display: flex;
    column-gap: 5px;
    z-index: 2;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 0 5px;
    box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.3)
  }
}

.App-link {
  color: #61dafb;
}

.main-card {
  background: rgba(223, 233, 248, 0.65);
  border: none;
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(30px);

  .nav-item .nav-link {
    color: #414141;;
  }

  .nav-pills .show > .nav-link {
    color: rgba(67, 130, 196, 1);
    background: transparent;
  }

  .nav-item .nav-link.active {
    color: rgba(67, 130, 196, 1);
    background: rgba(67, 130, 196, 0.1);
  }
}

.custom-card {
  border-radius: 16px;
  border-top: 1px solid #FFF;
  border-left: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.40);
}

.custom-hr {
  width: 10%;
  border-top: 3px solid #008bfd;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-select-search input:focus {
  --tw-ring-color: none !important;
}
